import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import TagSvg from '../images/tag-solid.svg'

//internal
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"



const BlogPostTemplate = ({ data, pageContext}) => {
  const post = data.contentfulBlogPost
  const { previous, next } = pageContext

  return (
    <Layout>
        <SiteMetadata title="Zurly Digital Discount Cards Blog" description={data.contentfulBlogPost.title} />
      <article id="blog" className="mx-4 md:mx-auto text-center md:text-left">
        <header className="text-center my-16 pt-24">
          <h1 className="font-black text-5xl">
              {post.title}
          </h1>
          <p className="blog-small"> Written by: {post.author} </p>
          <p className="blog-small"> Published: {post.createdAt} </p>  
        </header>
        {
          (post.featuredImage == null)
          ? <div></div>
          : <Img fluid={post.featuredImage.fluid} alt={post.featuredImage.description} className="max-h-800 " />
        }
        <div className="md:mt-24 md:w-3/5 mx-auto">
          <img className="w-4 h-4 inline-block" src={TagSvg} alt="Tag-svg" />
          { 
            (post.tags == null)
            ?<div></div>
            : 
            post.tags.map((tag, index)=> {
              let slugifiedTag= tag.toLowerCase().replace(/\s/g, "-");
              return <a key={`tag-${index}`} href={`/tag/${slugifiedTag}`} target="_self"className="ml-3 px-3 py-2 bg-gray-200 hover:bg-herobg text-center align-middle shadow-sm rounded-md font-bold">{` ${tag.toLowerCase()}  `}</a>
            })
          }
        </div>
        <section dangerouslySetInnerHTML={{ __html: post.body.childMarkdownRemark.html }} className="md:w-3/5 mx-auto mt-4"/>
        <hr className="my-8"/>
      </article>

      <nav>
        <ul className="list-none text-sm mx-0 my-0">
          <li className="text-center">
            {previous && (
              <Link to={`/blog/${previous.slug}`} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li className="text-center">
            {next && (
              <Link to={`/blog/${next.slug}`} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>

    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: {eq: $slug}){
      body {
        childMarkdownRemark {
          excerpt(pruneLength: 200)
          html
        }
      }
      tags
      slug
      title
      createdAt(formatString: "MMMM DD, YYYY")
      featuredImage {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyContentfulFluid
        }
        description
      }
      author
    }
  }
`
